import loadable from '@loadable/component';

export default {
  MainPage: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/MainPage')),
  LoginPage: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/LoginPage')),
  NotFound: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/NotFound')),
  Home: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/Home')),
  Search: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/services/Search')),
  DomainForm: loadable(() => import( '../features/components/pages/domain/DomainForm')),
  TerminatedHousing: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/services/TerminatedHousing')),
  TerminatedStores: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/services/TerminatedStores')),
  PublishedStores: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/services/PublishedStores')),
  ProposalStores: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/services/ProposalStores')),
  ProposalStoresAnswer: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/services/proposalanswers/ProposalStoresAnswer')),
  SignContractStores: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/services/signcontractstores/SignContractStores')),
  ConfirmTerminations: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/services/ConfirmTerminations')),
  ConfirmTerminationsStores: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/services/ConfirmTerminationsStores')),
  Products: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/register/product/Products')),
  ProposalStore: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/register/reservation/ProposalStore')),
  MaintenanceObjects: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/register/maintenanceobject/MaintenanceObjects')),
  RegisterMeasures: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/register/maintenanceobject/registermeasures/RegisterMeasures')),
  Customers: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/register/customer/Customers')),
  Terminate: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/register/contract/Terminate')),
  TerminateStore: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/register/contract/TerminateStore')),
  TerminateResidentialLimit: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/register/contract/TerminateResidentialLimit')),
  ResidentialLimit: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/services/ResidentialLimit')),
  Certificates: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/services/Certificates')),
  AccessList: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/services/AccessList')),
  Labels: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/services/Labels')),
  TimeLimitContracts: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/services/TimeLimitContracts')),
  Kraftringen: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/services/Kraftringen')),
  QueueTime: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/services/QueueTime')),
  Lottery: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/services/Lottery')),
  TodoList: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/services/todolist/TodoList')),
  OfflineInspectionsList: loadable(() => import(/* webpackPrefetch: true */ 'features/components/pages/services/offline/OfflineInspectionsList')),
  Inspection: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/register/inspection/Inspection')),
  NewPassword: loadable(() => import('../features/components/pages/services/NewPassword')),
  Substitutes: loadable(() => import('../features/components/pages/services/substitutes/Substitutes')),
  Message: loadable(() => import('../features/components/pages/services/communication/Message')),
  Statistics: loadable(() => import('../features/components/pages/services/communication/Statistics')),
  Access: loadable(() => import('../features/components/pages/services/access/Access')),
  SelectProducts: loadable(() => import('../features/components/pages/services/access/SelectProducts')),
  ProductsExcel: loadable(() => import('../features/components/pages/services/download/ProductsExcel')),
  CustomersExcel: loadable(() => import('../features/components/pages/services/download/CustomersExcel')),
  MaintObjectsExcel: loadable(() => import('../features/components/pages/services/download/MaintObjectsExcel')),
  StoresExcel: loadable(() => import('../features/components/pages/services/download/StoresExcel')),
  ProductKeysExcel: loadable(() => import('../features/components/pages/services/download/ProductKeysExcel')),
  DetailsExcel: loadable(() => import('../features/components/pages/services/download/DetailsExcel')),
  AptusExcel: loadable(() => import('../features/components/pages/services/download/AptusExcel')),
  InvoiceExcel: loadable(() => import('features/components/pages/services/download/InvoiceExcel')),
  Documentation: loadable(() => import('../features/components/pages/other/Documentation')),
  DomainTreeView: loadable(() => import('../features/components/pages/domain/DomainTreeView')),
  SystemLog: loadable(() => import('../features/components/pages/other/SystemLog')),
  MiscinvoiceRoutine: loadable(() => import('features/components/pages/ledger/miscinvoice/MiscinvoiceRoutine')),
  InvoicecollectionRoutine: loadable(() => import('features/components/pages/ledger/invoicecollection/InvoicecollectionRoutine')),
  Info: loadable(() => import('../features/components/pages/other/Info')),
  FilesList: loadable(() => import('../features/components/pages/other/FilesList')),
  KeyloanOrder: loadable(() => import ('../features/components/pages/keyloan/KeyloanOrder')),
  TestPage: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/TestPage')),
  Fileupload: loadable(() => import(/* webpackPrefetch: true */ '../features/components/pages/other/FileUpload')),
  TrackchangeLog: loadable(() => import('features/components/pages/other/TrackchangeLog')),
  SystemActions: loadable(() => import('features/components/pages/other/SystemActions')),
  ClearRoutine: loadable(() => import('features/components/pages/other/clear/ClearRoutine')),
  StudyControlCreate: loadable(() => import('features/components/pages/control/StudycontrolExcel')),
} as const;
